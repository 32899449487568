// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// https://ag-grid.zendesk.com/hc/en-us/articles/360028846052-Ignoring-click-events-in-a-cell-when-listening-for-clicks-and-double-clicks
// Example: https://plnkr.co/edit/OSevoKlXGy6XRkBN?preview
// let clickDebouncer = debounce (actualOnCellClicked, 500);
// onCellClicked: (params) => {
//      clickDebouncer (params)
// },
export function debounce(func: Function, wait: number, immediate: boolean) {
    let timeout: ReturnType<typeof setTimeout> | null;
    return function (...params: any[]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        const args = params;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        if(timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
